

import { requests } from '@/tool/util'

export function Login(formData) {
    return requests("post",{
        url: "index/login",
        data: formData
    })
}

export function Logout() {
    return requests("post",{
        url: "index/logOut",
    })
}

export function GetUserInfo() {
    return requests("get",{
        url: "index/userinfo",
    })
}

export function getRoutes() {
    return requests("get",{
        url: "index/menus",
    })
}

export function GetBalanceLogs(formData) {
    return requests("get",{
        url: "UserBalanceLogs/getBalanceLogs",
        data: formData
    })
}



// 用户列表接口

export function LstUser(formData) {
    return requests("get",{
        url: `user/index`,
        data: formData
    })
}

export function ReadUser(id) {
    return requests("get",{
        url: `user/read/${id}`,
    })
}

export function UpdateUser(id,formData = {}) {
    return requests("put",{
        url: `user/update/${id}`,
        data: formData
    })
}
export function CreateUser(formData = {}) {
    return requests("post",{
        url: `user/create`,
        data: formData
    })
}

export function DeleteUser(id) {
    return requests("delete",{
        url: `user/delete/${id}`,
    })
}

export function EnterConsole(id) {
    return requests("get",{
        url: `user/console/${id}`,
    })
}


