<template>
    <div class="layout-container">
        <a-layout>
            <Header />
            <a-layout style="margin-top: 50px;">
                <a-layout-sider collapsedWidth="0" v-model:collapsed="isCollapsed" breakpoint="md" collapsible @collapse="onCollapse" :style="{background: '#FFF',userSelect: 'none' }">
                    <Menu :menu="RouteLst" @change="handleMenu()" />
                </a-layout-sider>
                <a-layout style="padding: 0 24px 24px;" :class="isCollapsed?'':'main-container'">
                    <a-breadcrumb style="margin: 16px 0;user-select: none;">
                        <a-breadcrumb-item v-for="(item,index) in breadcrumbData" :key="index">
                            <template v-if="item.is_menu">
                                <span style="cursor: pointer;">
                                    {{ item.label }}
                                </span>
                            </template>
                            <template v-else>
                                <span style="cursor: pointer;">
                                    {{ item.label }}
                                </span>
                            </template>
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-layout-content :style="{ background: '#fff', padding: '24px', minHeight: '280px',overflow: 'auto' }">
                        <router-view key="/dashboard" />
                        <div id="components-back-top-demo-custom">
                            <a-back-top>
                                <div class="ant-back-top-inner">
                                    TOP
                                </div>
                            </a-back-top>
                        </div>
                    </a-layout-content>
                </a-layout>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
    import Header from '@/layout/header'
    import Menu from '@/layout/menu'
    export default {
        components: {
            Header,
            Menu
        },
        data() {
            var RouteLst = this.$route.matched[0].children
            this.routesMap = new Map()
            this.initRouteMap(RouteLst)
            return {
                RouteLst: RouteLst,
                breadcrumbData: [],
                isCollapsed: false,
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(route) {
                    let _this = this
                    let readcrumb = route.matched.map((item) => {
                        return {
                            name: item.name,
                            is_menu: item.meta.menu_type == 2 ? 1 : 0,
                            label: _this.routesMap.get(item.name)
                        }
                    })
                    _this.breadcrumbData = readcrumb.slice(1)
                    let router = [];
                    for(let i in _this.breadcrumbData) {
                        let item = _this.breadcrumbData[i]
                        router.push(item.name)
                    }
                    if(router.length > 1) {
                        _this.$store.commit("SETOPENKEYS",[router[0]])
                        _this.$store.commit("SETSELECTEDKEYS",[router[1]])
                    } else {
                        _this.open_keys = []
                        _this.$store.commit("SETOPENKEYS",[])
                        _this.$store.commit("SETSELECTEDKEYS",router)
                    }
                },
            }
        },
        methods: {
            handleMenu() {
                let _this = this
                let w = window.innerWidth;
                if(w <= 770) {
                    _this.isCollapsed = true
                }
            },
            onCollapse(collapsed, type) {
                this.isCollapsed = collapsed
            },
            initRouteMap(routes) {
                let _this = this
                routes.forEach(route => {
                    _this.routesMap.set(route.name,route.meta.title)
                    if(route.children) {
                        _this.initRouteMap(route.children)
                    }
                });
            },
        }
    }
</script>



<style lang="less" scoped>


    body .ant-layout-sider {
        position: fixed !important;
        height: 100%;
        z-index: 999;
    }
    
    @media screen and (orientation: landscape){
        .main-container {
            margin-left: 200px;
            transition: 0.2s;
        }
    }

    #components-back-top-demo-custom .ant-back-top {
        bottom: 80px;
        right: 60px;
    }
    #components-back-top-demo-custom .ant-back-top-inner {
        height: 30px;
        width: 30px;
        line-height: 30px;
        border-radius: 4px;
        background-color: #1088e9;
        color: #fff;
        text-align: center;
        font-size: 12px;
    }
</style>



