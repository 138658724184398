import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

import lang from './assets/common/lang/index.js'
import { createI18n } from 'vue-i18n/index.mjs'
const i18n = createI18n({
    legacy: false, 
    locale: localStorage.getItem('lang') || 'ZhCn',
    messages: lang
})
app.use(i18n)

import store from './store'
app.use(store)

app.directive('iscan', {
    mounted(el, binding) {
        var value = binding.value ? binding.value : []
        if(value && value.length > 0) {
            let can = false
            let btnAuth = store.state.btn_auth
            for(let i in value) {
                let item = value[i]
                can = btnAuth.includes(item)
                if(can) {
                    break;
                }
            }
            // el.disabled = !can
            if(can == false){
                el.parentNode.removeChild(el);
            }
        }
    }
})


import router from "./router/index"
app.use(router)

import '@/tool/guard';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
app.use(Antd)

/** 获取路由参数 */
app.config.globalProperties.$Params = function () {
	return this.$route.query
}

app.config.globalProperties.setData = function (obj) {
	Object.assign(this.$data,obj);
}

import dayjs from 'dayjs'
app.config.globalProperties.$Date = function(time, format = "YYYY-MM-DD HH:mm:ss") {
    return dayjs(time).format(format)
}

app.config.globalProperties.$DeepCopy = function (obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    let copy = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            copy[key] = this.$DeepCopy(obj[key]);
        }
    }
    return copy;
}

import * as Icons from "@ant-design/icons-vue";
app.mount('#app')

import VueParticles from 'vue-particles'
app.use(VueParticles)

app.config.globalProperties.$icons = Icons
for (const i in Icons) {
    app.component(i, Icons[i]);
}
