import { requests } from '@/tool/util'

export function LstAdmin(formData) {
    return requests("get",{
        url: `admin/index`,
        data: formData
    })
}

export function ReadAdmin(id) {
    return requests("get",{
        url: `admin/read/${id}`,
    })
}

export function DeleteAdmin(id) {
    return requests("delete",{
        url: `admin/delete/${id}`,
    })
}

export function UpdateAdmin(id,formData = {}) {
    return requests("put",{
        url: `admin/update/${id}`,
        data: formData
    })
}

export function CreateAdmin(formData = {}) {
    return requests("post",{
        url: `admin/create`,
        data: formData
    })
}